import Component from '../core/Component'

export const States = {
    ACTIVE: 'is-active'
}

export default class Slides extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            slides: []
        }

        this.current = -1
        this.timer = null

        this.next = ::this.next
    }

    prepare() {
        this.show(0)
        if (this.ref.slides.length > 1) {
            this.start()
        }
    }

    destroy() {
        clearInterval(this.timer);
    }

    start() {
        this.timer = setInterval(this.next, 4000)
    }

    stop() {
        clearInterval(this.timer)
    }

    show(index) {
        if (this.current > -1) {
            this.ref.slides[this.current].classList.remove(States.ACTIVE)
        }

        setTimeout(() => {
            this.current = index
            this.ref.slides[this.current].classList.add(States.ACTIVE)
        }, 400);
    }

    next() {
        const next = (this.current + 1)% this.ref.slides.length
        this.show(next)
    }


}