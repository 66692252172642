import Component from '../core/Component'

export default class Ticker extends Component {

    constructor(element) {
        super(element)

        this.ref = {
            stripe: null,
            content: null,
            clone: null,
            moving: null,
            canvas: null,
        }

        this.raf = null

        this.current = 0
        this.length = 0
        this.speed = 3
        this.startingTime = new Date();
        this.textXpos = 5;
    }

    prepare() {
        // this.context = this.ref.canvas.getContext("2d");
        // this.context.canvas.width = this.ref.canvas.clientWidth;
        // this.context.canvas.height = this.ref.canvas.clientHeight;

        //this.text = this.ref.stripe.innerText;
        //this.text = this.text + " " + this.text + " " + this.text;
        //this.ref.moving.style.display = "none";
        this.ref.clone.innerText = this.ref.stripe.innerText;
        this.start();
    }

    render = () => {
        requestAnimationFrame(this.render);

        const currentTime = new Date() - this.startingTime;

        if (this.current > this.length + 8) {
            this.current = 0;
            this.startingTime = new Date();
        } else {
            this.current = currentTime/10;
        }

        //this.renderText();
        this.ref.moving.style.transform = `translate3d(${-this.current}px, 0, 0)`;
    }

    start() {
        this.length = this.ref.stripe.clientWidth;
        // this.length = this.context.measureText(this.text);
        setTimeout(() => {
            this.length = this.ref.stripe.clientWidth
            // this.length = this.context.measureText(this.text);
        }, 5000);
        this.render();
    }

    pause() {
        cancelAnimationFrame(this.raf)
    }

    destroy() {
        cancelAnimationFrame(this.raf)
    }

    renderText() {
        // Clear screen
        this.context.clearRect(0, 0, this.ref.canvas.clientWidth, this.ref.canvas.clientHeight);
        this.context.globalAlpha = 1;
        this.context.fillStyle = '#fff';
        this.context.fillRect(0, 0, this.ref.canvas.clientWidth, this.ref.canvas.clientHeight);

        // if (this.current < -textWidth/3) {
        //     this.textXpos = 0;
        // }

        this.context.font = '40px "Proxima Nova"';
        this.context.fillStyle = '#000000';
        this.context.textBaseline = 'top';
        this.context.fillText  ( this.text, -this.current, 8);
    }

}