import Component from '../core/Component';
import { loadComponents, removeComponents } from '../core/componentUtils';

const RELOAD_WAITING_TIME = 90 * 1000
const UPDATE_DELAY = 30 * 1000

export default class Watcher extends Component {

    constructor(element) {
        super(element);

        this.willReset = false
        this.reloadTimer = null
    }

    prepare() {
        this.request(this.element.getAttribute('data-url'), response => {
            this.lastUpdate = response;
        });

        this.loop = setInterval(::this.tryToUpdate, UPDATE_DELAY);
    }

    tryToUpdate() {
        this.request(this.element.getAttribute('data-url'), response => {
            if (this.lastUpdate !== response) {
                this.lastUpdate = response;
                this.updateContent();
            }
        });
    }

    updateContent() {
        document.body.classList.add('cover');

        setTimeout(() => {
            this.request(window.location.href, response => {
                let fakeDom = document.createElement('div');
                fakeDom.innerHTML = response;

                let watcherContent = fakeDom.querySelector('[data-component="Watcher"]').innerHTML;

                removeComponents(this.element);

                this.element.innerHTML = watcherContent;

                loadComponents(this.element);

                setTimeout(() => {
                   document.body.classList.remove('cover');
                }, 3000);
            });
        }, 1000);
    }

    request(url, callback) {
        let request = new XMLHttpRequest();
        request.onreadystatechange = function() {
            if (request.readyState === 4) {
                if (request.status !== 500) {
                    if (request.status === 205) {
                        if (!this.reloadTimer) {
                            this.reloadTimer = setTimeout(this.reload, RELOAD_WAITING_TIME)
                        }
                    } else {
                        callback(request.responseText)
                    }
                } else {
                    console.warn('Server error. Force reload.');
                    this.reload()
                }
            }
        }.bind(this)

        request.open('GET', url, true);
        request.send();
        return request;
    }

    reload = () => {
        window.location.reload()
    }

}
