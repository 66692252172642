import Component from '../core/Component';
import { loadComponents, removeComponents } from '../core/componentUtils';

export default class Watcher extends Component {

    constructor(element) {
        super(element);
    }

    prepare() {
        this.loop = setInterval(::this.updateContent, 60 * 60 * 4 * 1000);
    }

    updateContent() {
        this.request(window.location.href, response => {
            let fakeDom = document.createElement('div');
            fakeDom.innerHTML = response;

            let watcherContent = fakeDom.querySelector('[data-component="Weather"]').innerHTML;

            this.element.innerHTML = watcherContent;
        });
    }

    request(url, callback) {
        let request = new XMLHttpRequest();
        request.onreadystatechange = function() {
            if (request.readyState === 4) {
                if (request.status !== 500) {
                    callback(request.responseText)
                } else {
                    console.warn('Server error. Force reload.');
                    window.location.reload();
                }
            }
        }

        request.open('GET', this.element.getAttribute('data-url'), true);
        request.send();
        return request;
    }

}
