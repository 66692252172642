import Looper from './Looper'
import Slides from './Slides'
import Ticker from './Ticker'
import Time from './Time'
import Watcher from './Watcher'
import Weather from './Weather'

const Components = {
    Looper,
    Slides,
    Ticker,
    Time,
    Watcher,
    Weather,
}

export default Components