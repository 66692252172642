import Component from '../core/Component';

export default class Looper extends Component {

    constructor(element) {
        super(element);

        this.timers = {}
    }

    prepare() {
        this.index = -1;
        this.bg = document.querySelector('#bg');

        this.ref.blocks.forEach(block => {
            // remove videos at start and save info
            let video = block.querySelector('video');
            if (video) {
                block.video = video;
                block.videoSrc = video.src;
                video.src = "";

                block.getVideoLoaded = function () {
                    return new Promise(function (resolve, reject) {
                        video.addEventListener("loadedmetadata", () => {
                            resolve();
                        });
                    });
                };
            }

            // stop video and hide element
            block.stop = function() {
                block.classList.remove('is-active');
                if (video) {
                    block.video.currentTime = 0;
                    block.video.pause();
                    block.video.src = "";
                    //block.video.style.display = "none";
                }
            }

            block.type = block.getAttribute('data-type');

            // start video and display element
            block.start = function() {
                block.classList.add('is-active');
                if (video) {
                    block.video.currentTime = 0;
                    block.video.src = block.videoSrc;
                    block.video.style.display = "block";
                }
            }
        });

        if (this.ref.blocks.length >= 1) {
            this.next();
        }
    }

    destroy() {
        Object.keys(this.timers)
            .forEach(key => clearTimeout(this.timers[key]))
    }

    next() {
        this.index++;
        this.prevIndex = this.index - 1;

        if (this.index >= this.ref.blocks.length) {
            this.index = 0;
        }

        if (this.index === 0) {
            this.prevIndex = this.ref.blocks.length-1;
        }

        if (this.ref.blocks[this.prevIndex].type == "video" || this.ref.blocks[this.index].type == "video") {
            this.needsStopping = true;
        } else {
            this.needsStopping = false;
        }

        if (this.needsStopping) {
            document.body.classList.add('cover');
            setTimeout(() => {
                this.bg.style.display = "none";
            }, 500)
        }


        // play
        this.timers.play = setTimeout(() => {
            this.ref.blocks.forEach(block => {
                block.stop();
            });

            this.ref.blocks[this.index].start();

            //prijemnejsi prechod z jinych backgroundu, aby neprobliklo prekreslovani bg na jednom elementu
            if (this.bg.className.includes('Bg--')) {
                const clone = this.bg.cloneNode()
                this.bg.parentNode.insertBefore(clone, this.bg)
                setTimeout(() => {
                    this.bg.parentNode.removeChild(clone)
                }, 200)
            }

            this.bg.classList.forEach(className => {
                if (className.startsWith('Bg--')) {
                    this.bg.classList.remove(className)
                }
            })

            if (this.ref.blocks[this.index].video) {
                this.bg.style.display = "none";
                this.ref.blocks[this.index].getVideoLoaded().then(event => {
                    this.ref.blocks[this.index].video.play();
                    setTimeout(() => {
                        document.body.classList.remove('cover');
                    }, 500);
                });
            } else {
                setTimeout(() => {
                    document.body.classList.remove('cover');
                }, 1000);
                if (this.ref.blocks[this.index].dataset.bg) {
                    this.bg.classList.add(`Bg--${this.ref.blocks[this.index].dataset.bg}`)
                }
                //DOCASNE
                if (this.ref.blocks[this.index].dataset.slideNthOfType) {
                    this.bg.dataset.slideNthOfType = this.ref.blocks[this.index].dataset.slideNthOfType
                } else {
                    this.bg.dataset.slideNthOfType = null
                }
                //-DOCASNE
                this.bg.style.display = "block";
            }
        }, 500);

        this.timers.next = setTimeout(::this.next, parseInt(this.ref.blocks[this.index].getAttribute('data-timer')));
    }
}
