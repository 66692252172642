import moment from 'moment'
import Component from '../core/Component'

export default class Time extends Component {

    prepare() {
        this.serverTime = moment.utc(this.element.dataset.time)
        this.start = Date.now()
        this.timer = setInterval(::this.refresh, 1000)
        this.refresh()
        this.value = ''
    }

    refresh() {
        const offset = Date.now() - this.start
        const time = this.serverTime.clone().add(offset).utc().format('HH:mm')
        if (this.value !== time) {
            this.value = time
            this.element.innerText = time
        }
    }

    destroy() {
        clearTimeout(this.timer)
    }

}